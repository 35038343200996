import { getFromAPI, postToAPI, postBlobToAPI, wrongParams } from "./utils";

const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

import GetDimensionsAndPositions220 from "./json/GetDimensionsAndPositions[220].json";
import GetDimensionsAndPositions220Full from "./json/GetDimensionsAndPositions[220]_full.json";

export const getIndicatorsTreeFromAPI = async () => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const URL = `${BASE_API_URL}/wsk/getIndicatorsTree`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getVariableSectionsFromAPI = async ({ indicatorId, years, periods }) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const hasWrongParams = [];

    /** Check required parameters...*/
    if (!indicatorId) hasWrongParams.push("indicatorId");
    if (!years || !years.length) hasWrongParams.push("years");
    if (!periods || !periods.length) hasWrongParams.push("years");

    /** ...and return errors if any...*/
    if (hasWrongParams.length) return wrongParams(hasWrongParams.join(`, `));

    /** ...else request API */
    const yearsQuery = years.map((year) => `lata=${year}`).join("&");
    const periodsQuery = periods.map((period) => `okresy=${period}`).join("&");
    const URL = `${BASE_API_URL}/wsk/getVariableSections?id_zmiennej=${indicatorId}&${yearsQuery}&${periodsQuery}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getTypeOfInformationFromAPI = async ({ indicatorId, sectionId, years, periods }) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const hasWrongParams = [];

    /** Check required parameters...*/
    if (!indicatorId) hasWrongParams.push("indicatorId");
    if (!sectionId) hasWrongParams.push("sectionId");
    if (!years || !years.length) hasWrongParams.push("years");
    if (!periods || !periods.length) hasWrongParams.push("years");

    /** ...and return errors if any...*/
    if (hasWrongParams.length) return wrongParams(hasWrongParams.join(`, `));

    /** ...else request API */
    const yearsQuery = years.map((year) => `lata=${year}`).join("&");
    const periodsQuery = periods.map((period) => `okresy=${period}`).join("&");
    const URL = `${BASE_API_URL}/wsk/getTypeOfInformation?id_zmiennej=${indicatorId}&id_przekroju=${sectionId}&${yearsQuery}&${periodsQuery}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getDimensionForSectionVarible = async ({ indicatorId, sectionId, years, periods }) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const hasWrongParams = [];

    /** Check required parameters...*/
    if (!indicatorId) hasWrongParams.push("indicatorId");
    if (!sectionId) hasWrongParams.push("sectionId");
    if (!years || !years.length) hasWrongParams.push("years");
    if (!periods || !periods.length) hasWrongParams.push("years");

    /** ...and return errors if any...*/
    if (hasWrongParams.length) return wrongParams(hasWrongParams.join(`, `));

    /** ...else request API */
    const yearsQuery = years.map((year) => `lata=${year}`).join("&");
    const periodsQuery = periods.map((period) => `okresy=${period}`).join("&");
    const URL = `${BASE_API_URL}/wsk/getDimensionForSectionVarible?id_zmiennej=${indicatorId}&id_przekroju=${sectionId}&${yearsQuery}&${periodsQuery}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getDimensionsAndPositions = async (args) => {
    let { sectionIds, lang } = args;

    const _isPl = lang === "pl";

    /** remove */
    if (sectionIds?.includes(1136)) {
        // return GetDimensionsAndPositions220;
        // return GetDimensionsAndPositions220Full
    }
    /** /remove */

    /** Check required parameters and return errors if any...*/
    if (!sectionIds || !sectionIds.length) return wrongParams("sectionIds");

    /** ...else request API */
    const query = sectionIds.map((id) => `id_przekoju=${id}`).join("&");
    const URL = `${BASE_API_URL}/wsk/GetDimensionsAndPositions?${query}&czyPL=${_isPl}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getSearchBarResults = async ({ searchText, isPl, typ_id, signal }) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!searchText) return wrongParams("searchText");
    if (isPl !== true) isPl = false;

    /** ...else request API */
    const query = `tekst=${encodeURIComponent(searchText)}&czyPl=${isPl}&typ_id=${typ_id}`;
    const URL = `${BASE_API_URL}/wsk/getSearchBarResults?${query}`;
    const DATA = await getFromAPI(URL, signal);
    return DATA;
};

export const getDefaultMetaTableFromAPI = async ({
    indicators,
    years,
    periods,
    customer_section,
    customer_dimension,
    customer_position,
}) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const hasWrongParams = [];

    /** Check required parameters...*/
    if (!indicators || !indicators.length) hasWrongParams.push("indicators");
    if (!years || !years.length) hasWrongParams.push("years");
    if (!periods || !periods.length) hasWrongParams.push("years");

    /** ...and return errors if any...*/
    if (hasWrongParams.length) return wrongParams(hasWrongParams.join(`, `));

    /** ...else request API */
    const indicatorsQuery = indicators.map((indicator) => `id_zmiennej=${indicator}`).join("&");
    const yearsQuery = years.map((year) => `lata=${year}`).join("&");
    const periodsQuery = periods.map((period) => `okresy=${period}`).join("&");

    // Test link: http://localhost:11000/baza-danych?id=571&s=413&d=93&p=1

    const URL = `${BASE_API_URL}/wsk/getDefaultMetaTable?${indicatorsQuery}&${yearsQuery}&${periodsQuery}${
        customer_section ? `&id_przekroj=${customer_section}` : ""
    }${customer_dimension ? `&id_wym=${customer_dimension}` : ""}${
        customer_position ? `&id_pos=${customer_position}` : ""
    }`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getTableNewManyIndicatorsFromAPI = async (params) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!params) return wrongParams("params");

    /** ...else request API */
    const URL = `${BASE_API_URL}/wsk/GetTableNewManyIndicators`;
    const data = params;
    const DATA = await postToAPI(URL, data);
    return DATA;
};

export const getTableNewManyIndicatorsNewFromAPI = async (params, signal) => {
    // process.env.NODE_ENV === "development" && console.log('%c getTableNewManyIndicatorsNewFromAPI ','background: #090;', signal, signal?.aborted)

    /** Check required parameters and return errors if any...*/
    if (!params) return wrongParams("params");

    /** ...else request API */
    const URL = `${BASE_API_URL}/wsk/GetTableNewManyIndicatorsNew`;
    const data = params;
    const DATA = await postToAPI(URL, data, signal);
    return DATA;
};

export const getTableExportFromAPI = async (params) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!params) return wrongParams("params");

    /** ...else request API */
    const URL = `${BASE_API_URL}/wsk/GetTableNewManyIndicatorsNew`;
    const data = params;
    const DATA = await postBlobToAPI(URL, data);
    return DATA;
};
