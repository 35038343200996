import { getFromAPI } from "./utils";

const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

export const getDbStatusFromAPI = async () => {
    const URL = `${BASE_API_URL}/getStatus`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getApiVersionFromAPI = async () => {
    // const URL = `${BASE_API_URL}/getApiVersion`;
    const URL = `${BASE_API_URL}/GetBuildNumber`;
    
    // return "0001"
    const DATA = await getFromAPI(URL);
    return DATA;
};
