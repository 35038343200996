import { getFromAPI, getBlobFromAPI, postToAPI, wrongParams } from "./utils";

const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

export const getPeriodTypesFromAPI = async () => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const URL = `${BASE_API_URL}/getPeriodTypes`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getTimeSeriesFromAPI = async (IDs) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!IDs || !IDs.length) return wrongParams("IDs");

    /** ...else request API */
    const query = IDs.map((id) => `id_list=${id}`).join("&");
    const URL = `${BASE_API_URL}/getTimeSeries?${query}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getTerritorialUnitsFromAPI = async (IDs) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!IDs || !IDs.length) return wrongParams("IDs");

    /** ...else request API */
    const query = IDs.map((id) => `id_list=${id}`).join("&");
    const URL = `${BASE_API_URL}/getTerritorialUnits?${query}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getCertificateFromAPI = async (ID) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!ID) return wrongParams("ID");

    /** ...else request API */
    const query = [ID]
        .flat()
        .map((id) => `zmienna=${id}`)
        .join("&");
    // const URL = `${BASE_API_URL}/api/getCertificate?zmienna=${ID}`;
    const URL = `${BASE_API_URL}/getCertificate?${query}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getTopMenuFromAPI = async () => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const URL = `${BASE_API_URL}/getTopMenu`;
    // const _tmpDevCatalog = {
    //     id: "cat",
    //     title: "Katalog",
    //     title_en: "Catalog",
    //     href: "/katalog/wartosc",
    //     is_areas: false,
    //     children: [
    //         {
    //             id: "cat1",
    //             title: "Dane o wysokiej wartości",
    //             title_en: "High-value data",
    //             href: "/katalog/wartosc",
    //         },
    //         {
    //             id: "cat2",
    //             title: "Dane do waloryzacji",
    //             title_en: "Data for valorization",
    //             href: "/katalog/waloryzacja",
    //         },
    //     ],
    // };
    const DATA = await getFromAPI(URL);

    // const newData = [...DATA.slice(0, 3), _tmpDevCatalog, ...DATA.slice(3)];
    // return newData;
    return DATA;
};

export const getHelpFromAPI = async () => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const URL = `${BASE_API_URL}/getHelp`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const postModulesForAreaByIDFromAPI = async (params) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!params) return wrongParams("params");

    /** ...else request API */
    const URL = `${BASE_API_URL}/postModulesForAreaByID`;
    const data = params;
    const DATA = await postToAPI(URL, data);
    return DATA;
};

export const postContactToAPI = async (params) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!params) return wrongParams("params");

    /** ...else request API */
    const URL = `${BASE_API_URL}/postContact`;
    const data = params;
    const DATA = await postToAPI(URL, data);
    return DATA;
};

export const getChartByIdFromAPI = async ({ id_chart, czy_jezyk_pl }) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!id_chart) return wrongParams("chartID");

    /** ...else request API */
    const URL = `${BASE_API_URL}/ChartByID`;
    const data = { id_chart, czy_jezyk_pl };
    const DATA = await postToAPI(URL, data);
    return DATA;
};

export const getMetrykaCSVFromAPI = async ({ indicators }) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const hasWrongParams = [];

    /** Check required parameters...*/
    if (!indicators) hasWrongParams.push("indicators");

    /** ...and return errors if any...*/
    if (hasWrongParams.length) return wrongParams(hasWrongParams.join(`, `));

    /** ...else request API */
    const indicatorsQuery = indicators.map((indicator) => `id_zmienne=${indicator}`).join("&");

    const URL = `${BASE_API_URL}/GetMetrykaCSV?${indicatorsQuery}`;
    const DATA = await getBlobFromAPI(URL);
    return DATA;
};
