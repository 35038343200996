import { getFromAPI, postToAPI, wrongParams } from "./utils";

const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
const API_PRE_PATH = "/api";

import SEARCH_ADV from "./json/getSearchAdvanced.sample.json";

export const getHomepageAreasFromAPI = async () => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const URL = `${BASE_API_URL}/getHomepageAreas`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getHomepageAreasByIdFromAPI = async ({ areaID }) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!areaID) return wrongParams("areaID");

    /** ...else request API */
    const URL = `${BASE_API_URL}/getHomepageAreaByID/${areaID}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const searchByStringFromAPI = async ({ searchString }) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!searchString) return wrongParams("searchString");

    /** ...else request API */
    const URL = `${BASE_API_URL}/searchBy/${searchString}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const searchResultsFromAPI = async ({ searchString }) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!searchString) return wrongParams("searchString");

    /** ...else request API */
    const URL = `${BASE_API_URL}/searchResults/${searchString}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const searchAdvancedFromAPI = async ({ searchText, isPl, typ_id, page, per_page = 5, isSample = false }) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!searchText) return wrongParams("searchText");
    if (isPl !== true) isPl = false;

    const query = `tekst=${encodeURIComponent(searchText)}&czyPl=${isPl}&typ_id=${typ_id}`;
    const URL = `${BASE_API_URL}/getSearchAdvanced?${query}`;

    const DATA = await getFromAPI(URL);

    /** remove */
    return isSample ? SEARCH_ADV : DATA;
    /** / remove */

    return DATA;
};

export const getOECDFromAPI = async () => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const URL = `${BASE_API_URL}/getOECD`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

// export const getTopMenuFromAPI = async () => {
//     const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
//     const URL = `${BASE_API_URL}/getTopMenu`;
//     const DATA = await getFromAPI(URL);
//     return DATA;
// };

export const getAreasMenuFromAPI = async () => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const URL = `${BASE_API_URL}/getAreasMenu`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getModulesForAreaByIdFromAPI = async ({ areaID }) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!areaID) return wrongParams("areaID");

    /** ...else request API */
    const URL = `${BASE_API_URL}/getModulesForAreaByID/${areaID}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

// export const getChartByIdFromAPI = async ({ id }) => {
//     // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

//     /** Check required parameters and return errors if any...*/
//     if (!id) return wrongParams("chartID");

//     /** ...else request API */
//     const URL = `${BASE_API_URL}/ChartByID`;
//     const data = { id };
//     const DATA = await postToAPI(URL, data);
//     return DATA;
// };

export const geWidgetByIdFromAPI = async ({ id, lang, forStory }) => {
    /** Check required parameters and return errors if any...*/
    if (!id) return wrongParams("widgetID");

    /** ...else request API */
    const URL = forStory ? `${BASE_API_URL}/DashByID` : `${BASE_API_URL}/WidgetByID`;
    // const data = { id, lang };

    const data = { id_widget: id, czy_jezyk_pl: lang === "pl" };
    const DATA = await postToAPI(URL, data);
    return DATA;
};

export const getCalendarOptions = async ({ isPl }) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const URL = `${BASE_API_URL}/getCalendarOptions?czy_pl=${isPl}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getCalendarByParamsFromAPI = async (params) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    // if (!id) return wrongParams("widgetID");

    /** ...else request API */
    const URL = `${BASE_API_URL}/getCalendarByParams`;
    const data = params;
    const DATA = await postToAPI(URL, data);
    return DATA;
};

export const getFAQFromAPI = async () => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    // const URL = `${BASE_API_URL}/getFAQ`;
    const URL = `${BASE_API_URL}/getHelp`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getHelpFromAPI = async () => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const URL = `${BASE_API_URL}/getHelp`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getIndicatorByIdFromAPI = async ({ IDs }) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!IDs?.length) return wrongParams("IDs");

    /** ...else request API */
    const IDsQuery = IDs.map((ID) => `id=${ID}`).join("&");
    const URL = `${BASE_API_URL}/wsk/getIndicatorByID/?${IDsQuery}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getHomepageAreasGroupedFromAPI = async () => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const URL = `${BASE_API_URL}/getHomepageAreasGrouped`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

export const getDbChartsFromAPI = async (params) => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    /** Check required parameters and return errors if any...*/
    if (!params) return wrongParams("params");

    /** ...else request API */
    const URL = `${BASE_API_URL}/GetDbChart`;
    const data = params;
    const DATA = await postToAPI(URL, data);
    return DATA;
};

export const makeShareLinkFromAPI = async (params) => {
    /** Check required parameters and return errors if any...*/
    if (!params) return wrongParams("params");

    /** ...else request API */
    const URL = `${BASE_API_URL}/makeShareLink`;
    const data = params;
    const DATA = await postToAPI(URL, data);
    return DATA;
};

export const takeShareLinkFromAPI = async (params) => {
    /** Check required parameters and return errors if any...*/
    if (!params) return wrongParams("params");

    /** ...else request API */
    const URL = `${BASE_API_URL}/takeShareLink`;
    const data = params;
    const DATA = await postToAPI(URL, data);
    return DATA;
};

export const getTest503FromAPI = async () => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const URL = `${BASE_API_URL}/getTopMenu503`;
    const DATA = await getFromAPI(URL);
    return DATA;
};
