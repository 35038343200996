import { getFromAPI, postToAPI, postBlobToAPI, wrongParams } from "./utils";
import { GetAllIDsAlt } from "../_common/useHelper";

const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

export const getIndicatorsListFromAPI = async () => {
    // const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
    const URL = `${BASE_API_URL}/wsk/getIndicatorsTree`;
    const DATA = await getFromAPI(URL);

    if (DATA?.length) {
        const _indicators = GetAllIDsAlt(DATA)
            ?.filter((r) => r.type === "INDICATOR")
            //?.slice(0, 99);

        return _indicators;
    } else {
        return DATA;
    }
};
