const ACCENT = "#8f72bc"; //"#C8720F"

export const data = {
    calc_id: 2,
    title: "Drogowe kontrakty infrastrukturalne",
    formula: [
        "\\[ W_n = a \\times 100 + b \\times {CPI_n \\over CPI_0} + c \\times {P_n \\over P_0} + d \\times {R_n \\over R_0} + e \\times {C_n \\over C_0} + f \\times {A_n \\over A_0} + g \\times {S_n \\over S_0} + h \\times {K_n \\over K_0} \\]",
    ],
    columns: [
        [
            {
                id: "h-0-1",
                label: "Wskaźniki do waloryzacji wynagrodzenia wykonawcy",
            },
            {
                id: "h-0-2",
                label: "Symbol wskaźnika z klauzuli waloryzacyjnej",
            },
            {
                id: "h-0-3",
                label: "Symbol wagi",
            },
            {
                id: "h-0-4",
                label: "Symbol",
            },
            {
                id: "h-0-5",
                label: "Podstawa 2018 M12=100 (2022 M11)",
            },
            {
                id: "h-0-6",
                label: "Podstawa 2018 M12=100 (2022 M12)",
            },
            {
                id: "h-0-7",
                label: "Podstawa (2022 M11) (2022 M12)",
            },
            {
                id: "h-0-8",
                label: "Waga",
            },
        ],
    ],
    cellFormats: [
        {
            idx: 0,
            align: "left",
        },
    ],
    rows: [
        [
            "Współczynnik - niepodlegający waloryzacji (a)",
            "",
            "a",
            "",
            "",
            "",
            "",

            /** issue #312 */
            {
                value: "0,5",
                accent: ACCENT, // HEX color lub TRUE dla ustawienia domyślnego ("#C8720F")
            },
            /** /issue #312 */
        ],
        [
            "Wskaźnik cen produkcji sprzedanej wyrobów przemysłowych - kruszywo (grudzień 2018=100)",
            "K",
            "h",
            "08.1",
            "143,50",
            "144,80",
            "100,91",
            "0,04",
        ],
        [
            {
                value: "Wskaźnik cen produkcji sprzedanej wyrobów przemysłowych - asfalt (grudzień 2018=100)",
                accent: ACCENT,
            },
            { value: "A", accent: ACCENT },
            { value: "f", accent: ACCENT },
            { value: "08.9", accent: ACCENT },
            { value: "153,80", accent: ACCENT },
            { value: "154,90", accent: ACCENT },
            { value: "100,72", accent: ACCENT },
            { value: "0,08", accent: ACCENT },
        ],
        [
            "Wskaźnik cen produkcji sprzedanej wyrobów przemysłowych - paliwo (grudzień 2018=100)",
            "P",
            "c",
            "19.2",
            "211,20",
            "199,80",
            "94,60",
            "0,06",
        ],
        [
            "Wskaźnik cen produkcji sprzedanej wyrobów przemysłowych - cement (grudzień 2018=100)",
            "C",
            "e",
            "23.5",
            "161,20",
            "161,80",
            "100,37",
            "0,04",
        ],
        [
            "Wskaźnik cen produkcji sprzedanej wyrobów przemysłowych - stal (grudzień 2018=100)",
            "S",
            "g",
            "24.1",
            "156,60",
            "147,70",
            "94,32",
            "0,03",
        ],
        [
            "Dynamika przeciętnego miesięcznego wynagrodzenia brutto w sektorze przedsiębiorstw - inżynieria lądowa i wodna (grudzień 2018=100)",
            "R",
            "d",
            "F.42",
            "126,70",
            "137,70",
            "108,68",
            "0,05",
        ],
        [
            "Wskaźnik cen towarów i usług konsumpcyjnych (grudzień 2018=100)",
            "CPI",
            "b",
            "C.00",
            "133,70",
            "133,80",
            "100,07",
            "0,2",
        ],
    ],
    definitions: [
        {
            label: "a",
            desc: "współczynnik (niepodlegający waloryzacji)",
        },
        {
            label: "b",
            desc: "waga CPI",
        },
        {
            label: "c",
            desc: "waga paliwo",
        },
        {
            label: "d",
            desc: "waga robocizna",
        },
        {
            label: "e",
            desc: "waga cement",
        },
        {
            label: "f",
            desc: "waga asfalt",
        },
        {
            label: "g",
            desc: "waga stal",
        },
        {
            accent: ACCENT, // "#C8720F",
            label: "",
            desc: "lorem ipsum",
        },
        {
            label: "h",
            desc: "waga kruszywo",
        },
        {
            accent: ACCENT, // "#C8720F",
            label: "współczynnik (niepodlegający waloryzacji)",
            desc: "lorem ipsum",
        },
    ],
    widgets: [
        {
            id: 1,
            type: "NV",
            width: 3,
            name: "Kwota podlegająca waloryzacji (PLN)",
            name_en: "Value subject to indexation (in PLN)",
            value: 0.0,
            precision: 2,
            order: 0,
        },
        {
            id: 2,
            type: "NV",
            width: 3,
            name: "Wartość mnożnika korygującego",
            name_en: "The value of the corrective multiplier",
            value: 100.0624,
            precision: 4,
            order: 1,
        },
        {
            id: 3,
            type: "NV",
            width: 3,
            name: "Kwota po waloryzacji (PLN)",
            name_en: "Value after indexation (in PLN):",
            value: 0.0,
            precision: 2,
            order: 2,
        },
        {
            id: 4,
            type: "HTML",
            width: 3,
            html: "<p>Obliczenia dokonane za pomocą kalkulatora są <strong>według formuły ustalonej przez Generalną Dyrekcję Dróg Krajowych i Autostrad</strong></p>",
            html_en:
                "<p>Calculations made with the use of the calculator <strong>according to the formula developed by the General Directorate for National Roads and Motorways</strong></p>",
        },
    ],
    note: {
        title: "Zastrzeżenia prawne",
        text: "Niniejszy mnożnik ma charakter wyłącznie informacyjny i nie stanowi wskaźnika w rozumieniu przepisów prawa, a w szczególności mnożnik nie jest wskaźnikiem w rozumieniu ustawy z dnia 29 czerwca 1995 r. o statystyce publicznej. Mnożnik, wynik obliczeń powstałych w oparciu o zaproponowany mnożnik nie są oficjalnym stanowiskiem Prezesa Głównego Urzędu Statystycznego. Prezes GUS nie ponosi odpowiedzialności z tytułu ewentualnych szkód poniesionych przez podmioty korzystające z zaproponowanego mnożnika.",
    },

    /** issue #308
     * Object
     * lub TRUE dla ustawienia domyślnego ("Ceny")
     *
     * korzystanie z Object pozwoli elastycznie zarządzać przekierowaniem nie tylko do węzła drzewa "Ceny" w panelu strony "Dane", ale także do innych węzłów po stronie backendu
     */
    area: {
        id: 12, // optional
        name: "Ceny", // required
        name_en: "Prices", // optional
    },

    group_id: 1,
    /** /issue #308 */
};
