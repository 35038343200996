import { getFromAPI, postToAPI, postBlobToAPI, wrongParams } from "./utils";

/** mock data */
import VALUES from "./json/getCatalogValues.json";
import VALUES20240517 from "./json/getCatalogValues_20240517.json";
import CALC_METAS from "./json/getCalculatorMeta.json";

import CALC_A_MODEL from "./json/calcA.model.json";
import CALCULATORS from "./json/calculators.json";
import GET_CALCULATOR_BY_ID_2 from "./json/getCalculatorById=2.pl.json";
import GET_CALCULATOR_BY_ID_4 from "./json/getCalculatorById=4.pl.json";
import GET_CALCULATOR_BY_ID_9997 from "./json/getCalculatorById=9997.pl.json";
import { data as POST_CALC_RES_ID_9997 } from "./json/response.postCalculatorResults[id=2].sample.js";
import GET_AGGREGATES_BY_ID from "./json/getAggregatesById.sample.json";

const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

/** on error +, mock */
// TODO: switch to a real API
export const getCatalogValuesFromAPI = async ({ lang }) => {
    const czy_pl = lang === "pl";

    const URL = `${BASE_API_URL}/getCatalogValues?czy_pl=${czy_pl}`;
    const DATA = await getFromAPI(URL);
    // return DATA

    const _tmpDevData = VALUES;
    const _niceError = {
        message: {
            type: "Error",
            text: `Błąd lub brak metadanych`,
            text_en: `Error or missing metadata`,
        },
        error: true,
        sample: _tmpDevData,
    };

    // return VALUES20240517
    return DATA?.message?.text === "404" ? _niceError : DATA;

    // return _tmpDevData;
};

/** on error +, API */
export const getValorizationTreeFromAPI = async () => {
    const URL = `${BASE_API_URL}/getValorizationTree`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

/** on error +, API */
export const getCalculatorByIdFromAPI = async ({ id, lang }) => {
    // TODO: remove
    if (id?.toString() === "9992") {
        id = 2;
        return { id, model: GET_CALCULATOR_BY_ID_2 };
    }

    if (id?.toString() === "9994") {
        id = 4;
        return { id, model: GET_CALCULATOR_BY_ID_4 };
    }

    if (id?.toString() === "9997") {
        id = 4;
        return { id, model: GET_CALCULATOR_BY_ID_9997 };
    }
    // /TODO

    const czy_pl = lang === "pl";
    const URL = `${BASE_API_URL}/getCalculatorById?id=${id}&czy_pl=${czy_pl}`;
    const DATA = await getFromAPI(URL);
    return DATA?.message?.type === "Error" ? DATA : { id, model: DATA };
};

/** on error +, API */
export const getCatalogSampleTableByIdFromAPI = async ({ id, lang }) => {
    const czy_pl = lang === "pl";
    const URL = `${BASE_API_URL}/getCatalogSampleTableById?id=${id}&czy_pl=${czy_pl}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

/** on error +, API */
export const getCalculatorDatesAndAmountByIdFromAPI = async ({ id }) => {
    // TODO: remove
    if (id?.toString() === "9992") {
        id = 1;
    }
    // /TODO

    const URL = `${BASE_API_URL}/getCalculatorDatesAndAmount?id=${id}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

/** on error +, API */
export const postCalculatorResultsFromAPI = async (params) => {
    /** Check required parameters and return errors if any...*/
    if (!params) return wrongParams("params");

    // TODO: remove
    if (params?.data?.id?.toString() === "9997") {
        return POST_CALC_RES_ID_9997;
    }
    // /TODO

    /** ...else request API */
    const URL = `${BASE_API_URL}/postCalculatorResults`;
    const DATA = await postToAPI(URL, params);
    return DATA;
};

/** on error +, API */
export const postCalculatorResultsExportFromAPI = async (params) => {
    /** Check required parameters and return errors if any...*/
    if (!params) return wrongParams("params");

    /** ...else request API */
    const URL = `${BASE_API_URL}/postCalculatorResults`;
    const DATA = await postBlobToAPI(URL, params);
    return DATA;
};

/** on error +, API */
export const getCalculatorMetaByIdFromAPI = async ({ id, lang }) => {
    const czy_pl = lang === "pl";
    const URL = `${BASE_API_URL}/getCalculatorMeta?id=${id}&czy_pl=${czy_pl}`;
    const DATA = await getFromAPI(URL);
    return DATA;
};

/** on error +, mock */
// TODO: switch to a real API
export const getCalculatorMetaCSVByIdFromAPI = async ({ id }) => {
    const URL = `${BASE_API_URL}/getCalculatorMetaCSV?id=${id}`;
    // const DATA = await getFromAPI(URL);
    // return DATA;

    const blobed = new Blob(['hello; "world"'], {
        type: "text/csv;charset=utf8",
    });

    let _tmpDevData = { blobed, result: { errcode: 200 } };

    if (!_tmpDevData) {
        return {
            message: {
                type: "Error",
                text: `Błąd lub brak metadanych`,
                text_en: `Error or missing metadata`,
            },
            error: true,
        };
    }

    return _tmpDevData;
};

/** on error +, API */
export const getAggregatesByIdFromAPI = async ({ id, lang }) => {
    const czy_pl = lang === "pl";
    const URL = `${BASE_API_URL}/getAggregatesById?id=${id}&czy_pl=${czy_pl}`;

    /** remove */
    if (id?.toString()?.toUpperCase() === "TESTID") {

        await new Promise(resolve => setTimeout(resolve, 1000));
        return GET_AGGREGATES_BY_ID;
    } else {
    /** / remove */
        const DATA = await getFromAPI(URL);
        return DATA;
    }
};
