function handleErrors(response) {
    if (!response.ok) {
        process.env.NODE_ENV === "development" &&
            console.log("%c handleErrors ", "background: #F00;", response, response.body);
        throw Error(response.status, { cause: response });
    }
    return response;
}

export const getFromAPI = async (URL, signal) => {
    try {
        const DATA = await fetch(URL, { signal })
            .then(handleErrors)
            .then(async (response) => {
                let result;
                try {
                    result = await response.json();

                    if (result.message?.type === "Error") {
                        result.message = {
                            ...result.message,
                            text: result.message.pl || result.message.text,
                            text_en: result.message.en || result.message.text_en,
                        };
                    }
                } catch (e) {
                    process.env.NODE_ENV === "development" &&
                        console.log("%c get SMALL catch ", "background: #F00;", e);
                    result = {
                        ...result,
                        message: {
                            type: "Error",
                            text: result?.message?.text || `API mówi: "${response.status} ${response.statusText}"`,
                            text_en:
                                result?.message?.text_en || `API says: "${response.status} ${response.statusText}"`,
                        },
                        errcode: response.status,
                    };
                }
                if (!response.ok) {
                    result = {
                        ...result,
                        message: {
                            type: "Error",
                            text: result?.message?.text || `API mówi: "${response.status} ${response.statusText}"`,
                            text_en:
                                result?.message?.text_en || `API says: "${response.status} ${response.statusText}"`,
                        },
                        error: true,
                    };
                }
                return result;
            });
        return DATA;
    } catch (error) {
        // const _re = await error?.cause?.json();
        let _re = { message: { text: error.message,text_en: error.message } };
        try {
            _re = await error?.cause?.json();
        } catch (error) {}

        process.env.NODE_ENV === "development" &&
            console.log("%c get BIG catch ", "background: #F00;", { error, "error.message": error.message, _re });
        if (error.message == "503") {
            process.env.NODE_ENV === "development" && console.log("%c should redirect ", "background: #F00;");
            try {
                window.location.href = "/503";
                return;
            } catch (er) {
                process.env.NODE_ENV === "development" && console.log(er);
            }
        }
        return {
            message: {
                type: "Error",
                text: `Błąd lub brak metadanych`,
                text_en: `Error or missing metadata`,
                ...(_re?.message || {}),
            },
        };
    }
};

export const getBlobFromAPI = async (URL) => {
    try {
        const DATA = await fetch(URL)
            .then(handleErrors)
            .then(async (response) => {
                let result;
                let blobed;
                try {
                    blobed = await response.blob();
                    result = await response.json();

                    if (result.message?.type === "Error") {
                        result.message = {
                            ...result.message,
                            text: result.message.pl || result.message.text,
                            text_en: result.message.en || result.message.text_en,
                        };
                    }
                } catch (e) {
                    process.env.NODE_ENV === "development" &&
                        console.log("%c get SMALL catch ", "background: #F00;", e);
                    result = {
                        ...result,
                        message: {
                            type: "Error",
                            text: result?.message?.text || `API mówi: "${response.status} ${response.statusText}"`,
                            text_en:
                                result?.message?.text_en || `API says: "${response.status} ${response.statusText}"`,
                        },
                        errcode: response.status,
                    };
                }
                if (!response.ok) {
                    result = {
                        ...result,
                        message: {
                            type: "Error",
                            text: result?.message?.text || `API mówi: "${response.status} ${response.statusText}"`,
                            text_en:
                                result?.message?.text_en || `API says: "${response.status} ${response.statusText}"`,
                        },
                        error: true,
                    };
                }
                return { result, blobed };
            });
        return DATA;
    } catch (error) {
        process.env.NODE_ENV === "development" &&
            console.log("%c get BIG catch ", "background: #F00;", error, error.message);
        if (error.message == "503") {
            process.env.NODE_ENV === "development" && console.log("%c should redirect ", "background: #F00;");
            try {
                window.location.href = "/503";
                return;
            } catch (er) {
                process.env.NODE_ENV === "development" && console.log(er);
            }
        }
        return {
            message: {
                type: "Error",
                text: `Błąd lub brak metadanych`,
                text_en: `Error or missing metadata`,
            },
        };
    }
};

export const postToAPI = async (URL, data = {}, signal) => {
    // console.log({URL, data });
    if (!URL) {
        return {
            message: {
                type: "Error",
                text: `Błąd lub brak metadanych  (missing URL)`,
                text_en: `Error or missing metadata  (missing URL)`,
            },
            error: true,
        };
    }

    try {
        const DATA = await fetch(URL, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(data),
            signal,
        })
            .then(handleErrors)
            .then(async (response) => {
                let result;
                try {
                    result = await response.json();

                    if (result.message?.type === "Error") {
                        result.message = {
                            ...result.message,
                            text: result.message.pl || result.message.text,
                            text_en: result.message.en || result.message.text_en,
                        };
                    }
                } catch (e) {
                    process.env.NODE_ENV === "development" && console.log(response);
                    result = {
                        ...result,
                        message: {
                            type: "Error",
                            text: result?.message?.text || `API mówi: "${response.status} ${response.statusText}"`,
                            text_en:
                                result?.message?.text_en || `API says: "${response.status} ${response.statusText}"`,
                        },
                        errcode: response.status,
                    };
                }
                if (!response.ok) {
                    process.env.NODE_ENV === "development" && console.log(response, result);
                    result = {
                        ...result,
                        message: {
                            type: "Error",
                            text: result?.message?.text || `API mówi: "${response.status} ${response.statusText}"`,
                            text_en:
                                result?.message?.text_en || `API says: "${response.status} ${response.statusText}"`,
                        },
                        error: true,
                    };
                }
                return result;
            });
        return DATA;
    } catch (error) {
        const _re = await error?.cause?.json();

        process.env.NODE_ENV === "development" &&
            console.log("%c get BIG catch ", "background: #F00;", { error, "error.message": error.message, _re });
        if (error.message == "503") {
            process.env.NODE_ENV === "development" && console.log("%c should redirect ", "background: #F00;");
            try {
                window.location.href = "/503";
                return;
            } catch (er) {
                process.env.NODE_ENV === "development" && console.log(er);
            }
        }
        return {
            message: {
                type: "Error",
                text: `Błąd lub brak metadanych`,
                text_en: `Error or missing metadata`,
                ...(_re?.message || {}),
                catched: error.message,
            },
        };
    }
};

export const postBlobToAPI = async (URL, data = {}) => {
    // console.log({URL, data });
    if (!URL) {
        return {
            message: {
                type: "Error",
                text: `Błąd lub brak metadanych  (missing URL)`,
                text_en: `Error or missing metadata  (missing URL)`,
            },
            error: true,
        };
    }

    try {
        const DATA = await fetch(URL, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(data),
        })
            .then(handleErrors)
            .then(async (response) => {
                let result;
                let blobed;
                try {
                    blobed = await response.blob();
                    result = await response.json();

                    if (result.message?.type === "Error") {
                        result.message = {
                            ...result.message,
                            text: result.message.pl || result.message.text,
                            text_en: result.message.en || result.message.text_en,
                        };
                    }
                } catch (e) {
                    process.env.NODE_ENV === "development" && console.log(response);
                    result = {
                        ...result,
                        message: {
                            type: "Error",
                            text: result?.message?.text || `API mówi: "${response.status} ${response.statusText}"`,
                            text_en:
                                result?.message?.text_en || `API says: "${response.status} ${response.statusText}"`,
                        },
                        errcode: response.status,
                    };
                }
                if (!response.ok) {
                    process.env.NODE_ENV === "development" && console.log(response, result);
                    result = {
                        ...result,
                        message: {
                            type: "Error",
                            text: result?.message?.text || `API mówi: "${response.status} ${response.statusText}"`,
                            text_en:
                                result?.message?.text_en || `API says: "${response.status} ${response.statusText}"`,
                        },
                        error: true,
                    };
                }
                return { result, blobed };
            });
        return DATA;
    } catch (error) {
        process.env.NODE_ENV === "development" && console.log(error);
        if (error.message == "503") {
            process.env.NODE_ENV === "development" && console.log("%c should redirect ", "background: #F00;");
            try {
                window.location.href = "/503";
                return;
            } catch (er) {
                process.env.NODE_ENV === "development" && console.log(er);
            }
        }
        return {
            message: {
                type: "Error",
                text: `Błąd lub brak metadanych`,
                text_en: `Error or missing metadata`,
            },
        };
    }
};

export const wrongParams = (description = "") => {
    return {
        message: {
            type: "Error",
            text: `Wrong parameters for request${description ? ` (${description})` : ""}`,
        },
    };
};
